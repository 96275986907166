import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BarLoader } from "react-spinners";
import Content from "../views/Content";
const ContentContainer = (props) => {
  let name = props.history.location.state
    ? props.history.location.state.name
    : "Part Name";
  const { loading, categories, error, error_msg } = useSelector(
    (state) => state.Collection,
    shallowEqual
  );

  if (loading) {
    return (
      <div className="sweet-loading content">
        <BarLoader color={"#11cdef"} loading={true} size={150} />
      </div>
    );
  }
  if (error) {
    <Content {...props} />;
  }
  return <Content {...props} name={name} categories={categories} />;
};

export default ContentContainer;
