import React, {useState, useEffect} from 'react';
import {HiOutlineArrowUp} from 'react-icons/hi';
// import { Button } from 'reactstrap';
import { Button } from './Styles';

  
const ScrollButton = ({id}) =>{
  const [visible, setVisible] = useState(false)
  
  useEffect(() => {
    if(document.getElementById(id)){
 
 document.getElementById(id).addEventListener('scroll', toggleVisible);
}
  }, [document.getElementById(id)])
  
  const toggleVisible = () => {
    const scrolled = document.getElementById(id).scrollTop;

    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    document.getElementById(id).scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  


  
  return (
    <Button style={{display: visible ? 'inline' : 'none'}} >
     <HiOutlineArrowUp onClick={scrollToTop} 
     style={{display: visible ? 'inline' : 'none'}} />
    </Button>
  );
}
  
export default ScrollButton;