import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import { Collection } from "./reducers/collection";
// import Reactotron from '../config/reactotron' //for Development in developmet

const rootReducer = combineReducers({
  Collection,
});

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

export const configureStore = () => {
  // return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk), Reactotron.createEnhancer()));
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};
