import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { configureStore } from './store/store';
import App from './App';


import './assets/css/nucleo-icons.css';
import 'react-notification-alert/dist/animate.css';
import './assets/css/black-dashboard-pro-react.css';

// import './config/reactotron' 


const hist = createBrowserHistory();
export const store = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter history={hist}>
			<App hist={hist} />
		</BrowserRouter>
	 </Provider>,
  
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();