import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Card, CardBody, CardFooter, Row, Col, CardImg } from "reactstrap";

import "../styles/category.css";

const Category = (props) => {
  const [data, setdata] = useState(props.categories);
  const handleClick = (i, name) => {
    props.history.push({
      pathname: "/" + name,
      state: { id: i, name: name },
    });
  };
  useEffect(() => {
    document.addEventListener("scroll", () => alert("hi"));
  }, []);

  const SearchOptions = [];
  props.categories.map((data, index) =>
    SearchOptions.push({ value: data.id, label: data.name })
  );

  //Search Box Function
  const handleChange = (e) => {
    if (e) {
      const result = props.categories.filter((data) => data.id === e.value);
      setdata(result);
    } else {
      setdata(props.categories);
    }
  };
  const SortingOptions = [
    {
      label: "Number of Images",
      value: "imageCount",
      options: [
        {
          label: "Ascending (Images)",
          value: "imageCountAscending",
        },
        {
          label: "Descending (Images)",
          value: "imageCountDescending",
        },
      ],
    },
    {
      label: "Name",
      value: "name",
      options: [
        {
          label: "Ascending (Name)",
          value: "nameAscending",
        },
        {
          label: "Descending (Name)",
          value: "nameDescending",
        },
      ],
    },
  ];
  const handleSorting = (e) => {
    if (e) {
      if (e.value.includes("name")) {
        function compare(a, b) {
          let bandA = a.name.toUpperCase();
          let bandB = b.name.toUpperCase();

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          if (e.value === "nameAscending") return comparison;
          else return comparison * -1;
        }

        setdata([...data].sort(compare));
      } else {
        function compare(a, b) {
          let bandA = a.collection;
          let bandB = b.collection;

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          if (e.value === "imageCountAscending") return comparison;
          else return comparison * -1;
        }

        setdata([...data].sort(compare));
      }
    } else {
      setdata(props.categories);
    }
  };

  return (
    <div className="content" id="someid" style={{ transition: "2s" }}>
      <Row>
        <Col>
          <h2>Category</h2>
        </Col>
        <Col>
          <Select
            style={{ color: "red" }}
            options={SortingOptions}
            isSearchable={false}
            onChange={(event) => handleSorting(event)}
            clearable={true}
            isClearable={true}
            placeholder="Sort"
          />
        </Col>
        <Col>
          <Select
            closeMenuOnSelect={true}
            //   defaultValue={[SearchOptions[4], SearchOptions[5]]}
            //   isMulti
            options={SearchOptions}
            autosize={true}
            clearable={true}
            isClearable={true}
            onChange={(event) => handleChange(event)}
            placeholder="Search Here"
          />
        </Col>
      </Row>
      <Row>
        {data.map((data, index) => (
          <Col lg="3" md="4" sm="6" key={index}>
            <Card
              className="card-stats"
              style={{ width: "82%", background: "transparent" }}
            >
              <CardBody>
                <Row>
                  <Col>
                    <div className="hover03" style={{ padding: "8px" }}>
                      <CardImg
                        className="img"
                        onClick={() => handleClick(index, data.name)}
                        top
                        width="100%"
                        style={{ borderRadius: "25px" }}
                        src={data.src.replace("fashion", "tech")}
                        alt="Card image cap"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <h4>
                    {data.name}
                    <b style={{ float: "right", color: "green" }}>
                      {data.collection}
                    </b>
                  </h4>
                </div>
              </CardFooter>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

// Category.defaultProps = {
//   categories: [
//     {
//       id: 1,
//       src: "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/content/aed9d77734654eb58a84a1baffbd5abc.jpg",
//       name: "Door Handles",
//       collection: 153,
//     },
//     {
//       id: 2,
//       src: "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/content/5acd66083b324d85af60d491fd2dd4e8.jpg",
//       name: "Some Name",
//       collection: 46,
//     },
//     {
//       id: 3,
//       src: "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/content/5dd5ecc14942498ca0f881eb042c5f47.jpg",
//       name: "Some Name",
//       collection: 46,
//     },
//     {
//       id: 4,
//       src: "https://s3.ap-south-1.amazonaws.com/msil-s3-tdc-vision-production/feature_icons/nexa/s_cross/s_cross_master/keyless_entry_system_transmitter.png",
//       name: "Z",
//       collection: 46,
//     },
//     {
//       id: 5,
//       src: "https://s3.ap-south-1.amazonaws.com/msil-s3-tdc-vision-production/feature_icons/nexa/s_cross/s_cross_master/auto_dimming_rearview_mirror.png",
//       name: "Some Name",
//       collection: 86,
//     },
//     {
//       id: 6,
//       src: "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/content/b9c76f90f2ac466ca561345b9107dd61.jpg",
//       name: "Some Name",
//       collection: 1006,
//     },

//     //Repeat

//     {
//       id: 7,
//       src: "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/content/aed9d77734654eb58a84a1baffbd5abc.jpg",
//       name: "Door Handles",
//       collection: 153,
//     },
//     {
//       id: 8,
//       src: "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/content/5acd66083b324d85af60d491fd2dd4e8.jpg",
//       name: "Some Name",
//       collection: 46,
//     },
//     {
//       id: 9,
//       src: "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/content/5dd5ecc14942498ca0f881eb042c5f47.jpg",
//       name: "Some Name",
//       collection: 46,
//     },
//     {
//       id: 10,
//       src: "https://s3.ap-south-1.amazonaws.com/msil-s3-tdc-vision-production/feature_icons/nexa/s_cross/s_cross_master/keyless_entry_system_transmitter.png",
//       name: "A",
//       collection: 46,
//     },
//     {
//       id: 11,
//       src: "https://s3.ap-south-1.amazonaws.com/msil-s3-tdc-vision-production/feature_icons/nexa/s_cross/s_cross_master/auto_dimming_rearview_mirror.png",
//       name: "Some Name",
//       collection: 86,
//     },
//     {
//       id: 12,
//       src: "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/content/b9c76f90f2ac466ca561345b9107dd61.jpg",
//       name: "Some Name",
//       collection: 1006,
//     },
//   ],
// };

export default Category;
