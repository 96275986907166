import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Spinner } from "reactstrap"
import Category from '../views/Category'
import { fetchCollection } from '../store/actions/collection'


const CategoryContainer = (props) => {
    const {loading, categories, error, error_msg} = useSelector((state) => state.Collection, shallowEqual)

    const Dispatch = useDispatch()
    useEffect(() => {
        if(loading===true)
        Dispatch(fetchCollection())
      
    },[Dispatch]);
    
    if(loading)
    {
         return(
            <div className="sweet-loading content">
             <Spinner style={{ width: '5rem', height: '5rem' }} type="grow" />
             </div>

         )  
    }
    if(error)
    return <Category {...props}/>
    if(categories)
    {
       
        return <Category {...props} categories={categories}/>
    }
}

export default CategoryContainer
