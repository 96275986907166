import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar.js";
import CategoryContainer from "../../container/CategoryContainer";
import Content from "../../container/ContentContainer";
import ScrollButton from "../../utilities/ScrollButton";
// import routes from "../../routes";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: "blue",
      sidebarMini: true,
      opacity: 0,
      sidebarOpened: false,
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
      this.refs.mainPanel.addEventListener(
        "ps-scroll-y",
        this.showNavbarButton
      );
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", this.showNavbarButton);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.classList.add("perfect-scrollbar-off");
      document.documentElement.classList.remove("perfect-scrollbar-on");
      this.refs.mainPanel.removeEventListener(
        "ps-scroll-y",
        this.showNavbarButton
      );
    }
    window.removeEventListener("scroll", this.showNavbarButton);
  }
  componentDidUpdate(e) {
    if (e.location.pathname !== e.history.location.pathname) {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      this.refs.mainPanel.scrollTop > 50
    ) {
      this.setState({ opacity: 1 });
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      this.refs.mainPanel.scrollTop <= 50
    ) {
      this.setState({ opacity: 0 });
    }
  };

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened,
    });
    document.documentElement.classList.toggle("nav-open");
  };
  closeSidebar = () => {
    this.setState({
      sidebarOpened: false,
    });
    document.documentElement.classList.remove("nav-open");
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (true) {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
            exact={true}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    const view = this.props.categories.map((r, i) => {
      return {
        path: "/" + r.name,
        name: r.name,
        // mini: "B",
        component: Content,
      };
    });

    const routes = [
      {
        path: "/category",
        name: "Category",
        icon: "tim-icons icon-chart-pie-36",
        component: CategoryContainer,
        // layout: "/admin"
      },
      {
        collapse: true,
        name: "All Parts",
        icon: "tim-icons icon-settings",
        state: "pagesCollapse",
        views: [...view],
      },
    ];  
    return (
      <div className="wrapper" id="onn" style={{ backgroundColor: "#19191A" }}>
        {/* <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div> */}
        <div
          className="navbar-minimize-fixed"
          style={{ opacity: this.state.opacity }}
          style={{ backgroundColor: "#19191A" }}
        >
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={this.handleMiniClick}
          >
            <i className="tim-icons icon-double-right visible-on-sidebar-regular text-muted" />
            <i className="tim-icons icon-double-left visible-on-sidebar-mini text-muted" />
          </button>
        </div>
        <Sidebar
          {...this.props}
          routes={routes}
          activeColor={this.state.activeColor}
          logo={{
            outterLink: "#",
            text: "MSIL",
            //  imgSrc: logo
          }}
          closeSidebar={this.closeSidebar}
        />
        <div
          className="main-panel"
          ref="mainPanel"
          data={this.state.activeColor}
          style={{ backgroundColor: "#19191A" }}
        >
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            brandText="MSIL PARTS"
            sidebarOpened={this.state.sidebarOpened}
            toggleSidebar={this.toggleSidebar}
          />

          <Switch>
            {/* <Route path="/" exact component={Content} /> */}
            {this.getRoutes(routes)}
            <Redirect from="/" to="/category" />
          </Switch>

          {/* {// we don't want the Footer to be rendered on full screen maps page
          this.props.location.pathname.indexOf("full-screen-map") !==
          -1 ? null : (
            <Footer fluid />
          )} */}
        </div>
        <ScrollButton id="onn" />
      </div>
    );
  }
}

export default Admin;
