import { requestApi } from "../../config/apiHandler"

export const fetchCollectionStart = () => {
	return {
		type: 'FETCH_COLLECTION_START',
	};
};

export const fetchCollectionFail = (msg) => {
	return {
	  type: "FETCH_COLLECTION_FAIL",
	  payload: msg,
	};
  };
  
  export const dismissError = () => {
	return {
	  type: "DISMISS_ERROR_COLLECTION",
	};
  };
  

export const fetchCollection = () => {
	
	return async (dispatch) => {
		dispatch(fetchCollectionStart());

		const response = await requestApi(`/collection`, 'GET', {});
		// if (response.code == 'success') {
			
		if (response) { //for MOCKAPI
			
			dispatch({
				type: 'FETCH_COLLECTION_SUCCESS',
				// payload: response.data
				payload: response //for MOCKAPI
			});
	}  else if (response.code == 'authn_fail') {
		// dispatch(signOut());

	} else {
		dispatch({
			type: 'FETCH_COLLECTION_FAIL',
			payload: response.message
		});
};
}
}
