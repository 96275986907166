import React from "react";
import Swal from "sweetalert2";

export const ApiError = ({error_msg}) => {
    Swal.fire({
    icon: "error",
    title: "Oops...",
    text: error_msg,
    footer: '<a href="">Report this issue?</a>',
    confirmButtonText: "Try again!",
  }).then(function () {
    window.location.reload();
  })
    return null
  
};
export const NetworkError = () => {
   Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "No Internet Connection",
    showCancelButton: false,
    showConfirmButton: false,
  });
  return null
};
