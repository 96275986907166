const INITIAL_STATE = {
    loading: true,
      categories: [],
      error: false,
      error_msg: null,
    };
    
    export const Collection = (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case "FETCH_COLLECTION_START":
         
          return {
            ...state,
            loading: true,
          };
        case "FETCH_COLLECTION_SUCCESS":
          
  
          return {
            ...state,
            loading: false,
            categories: action.payload
          };
        case "FETCH_COLLECTION_FAIL":
          return {
            ...state,
            error: true,
            error_msg: action.payload,
            loading:false
          };
        case "DISMISS_ERROR_categories":
          return {
            ...state,
            error: false,
            error_msg: null,
          };
        default:
          return state;
      }
    };
    