import styled from 'styled-components';
  
  
export const Button = styled.div`
   position: fixed; 
   left: 94%;
   bottom: 50px;
   font-size: 2rem;
   z-index: 1;
   color: white;
   background:  rgb(50, 142, 182);
   cursor: pointer;
   border-radius: 9px;
   padding: 4px;
   opacity: 0.8;
`
