const axios = require("axios");
const API_URL = "https://61f1346e072f86001749f0b6.mockapi.io/";
//id- dataforretraining@some.com
//qwertyuiop -pass

const headers = {
  "x-origin": 3,
  "x-version": 1,
  // "x-auth": "47acdb51c39911eb86a10aba2aaf05ea",
  "Content-Type": "application/json",
};

export const requestApi = async (resourcePath, method, params) => {
  const url = API_URL + resourcePath;
  headers["x-auth"] = JSON.parse(await localStorage.getItem("x-auth"));

  if (resourcePath == "/auths/login" || resourcePath == "/updates") {
    delete headers["x-auth"];
  }

  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    let response = await axios({ url, method, data: params, headers });
    return response.data;
  } else {
    let response = await axios({ url, method, headers });
    return response.data;
  }
};
