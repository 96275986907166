import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Spinner } from "reactstrap";
import { Route, Switch } from "react-router-dom";
import { ApiError, NetworkError } from "./components/ErrorAlert";

import { fetchCollection } from "./store/actions/collection";
import AdminLayout from "./layouts/Admin/Admin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-50px",
  marginLeft: "-50px",
  width: "100px",
  height: "100px",
};

const App = (props) => {
  //I am doing this to show the categories on the sidebar

  const { loading, categories, error, error_msg } = useSelector(
    (state) => state.Collection,
    shallowEqual
  );
  const Dispatch = useDispatch();
  useEffect(() => {
    if (loading === true) Dispatch(fetchCollection());
  }, []);

  if (!navigator.onLine) {
      return(NetworkError())
    
  }

  if (loading) {
    return (
      <div className="content" style={style}>
        <Spinner style={{ width: "5rem", height: "5rem" }} type="grow" />
      </div>
    );
  }
  if (error) {
    return <div>{<ApiError error_msg={error_msg} />}</div>;
  }

  if (categories) {
    return (
      <div className="wrapper" style={{ backgroundColor: "#19191A" }}>
        <Switch>
          <Route
            path="/"
            render={(props) => (
              <AdminLayout {...props} categories={categories} />
            )}
          />
        </Switch>
      </div>
    );
  }
};

export default App;
